<template>
  <div class="row">
    <div class="col-md-5 mt-res-tab-big-10 mt-50 row-span-2">
      <div
        class="width-100 card shadow radius-10 pl-10 pr-10 pb-30 pt-30 h-full"
      >
        <span class="font-20 text-primary text-center pl-4 mb-10 font-weight-6 uppercase">
          <strong
            >Penduduk Sesuai Usia
            {{
              selectYear.penduduk == "all" ? "2020-2022" : selectYear.penduduk
            }}</strong
          >
        </span>

        <div class="width-100 flex justify-center">
          <b-form-select
            v-model="selectYear.penduduk"
            :options="yearOptions"
            return-object
            class="col-md-5 m-2"
          ></b-form-select>
        </div>
        <BarHome :filter_tahun="selectYear.penduduk" class="pb-4"></BarHome>
      </div>
    </div>
    <div class="col-md-7 mt-res-tab-big-10 mt-50 row-span-2">
      <div class="width-100 card shadow pt-3 pb-3 radius-10 h-full">
        <span class="font-20 text-primary text-center pl-4 font-weight-6 uppercase">
          <strong
            >Penduduk Papua Tahun
            {{ selectYear.oap == "all" ? "2020-2022" : selectYear.oap }}</strong
          >
        </span>
        <span class="font-18 text-warning text-center pl-4 font-weight-5">
          <strong>OAP dan Non OAP</strong>
        </span>
        <div class="width-100 flex justify-center">
          <b-form-select
            v-model="selectYear.oap"
            :options="yearOptions"
            return-object
            class="col-md-5 m-2"
          ></b-form-select>
        </div>
        <GrapichHome :filter_tahun="selectYear.oap" class="w-100"></GrapichHome>
      </div>
    </div>
  </div>
</template>

<script>
import BarHome from "./Bar-Home.vue";
import GrapichHome from "./grapich-penduduk-home.vue";

export default {
  name: "ChartSummary",
  components: {
    BarHome,
    GrapichHome,
  },
  data() {
    return {
      selectYear: {
        penduduk: "all",
        oap: "all",
      },
      yearOptions: [
        {
          text: "Tahun 2020-2022",
          value: "all",
        },
        {
          text: "Tahun 2020",
          value: "2020",
        },
        {
          text: "Tahun 2021",
          value: "2021",
        },
        {
          text: "Tahun 2022",
          value: "2022",
        },
      ],
    };
  },
};
</script>
