<template>
  <div>
    <!-- <vue-apex-charts type="bar" height="350" :options="columnChart.chartOptions" :series="columnChart.series"></vue-apex-charts> -->
    <highcharts ref="barChart" :options="chartOptions"></highcharts>
  </div>
</template>
<script>
// import VueApexCharts from "vue-apexcharts";
export default {
  components: {
    //  VueApexCharts
  },
  props: ["filter_tahun"],
  name: "chart-kependudkan",
  data() {
    return {
      themeColors: ["#07980C", "#28C76F"],
      columnChart: {
        series: [
          {
            name: "Total",
            data: [36, 35, 20, 50],
          },
        ],

        chartOptions: {
          colors: ["#1490F7", "#FF5306"],
          plotOptions: {
            bar: {
              horizontal: true,
              endingShape: "flat",
              columnWidth: "30%",
              barHeight: "30%",
              colors: {
                ranges: [
                  {
                    from: 0,
                    to: 100,
                    color: "#1490F7",
                  },
                ],
                backgroundBarColors: "#1490F7",
                backgroundBarOpacity: 0.1,
              },
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: [
              "Total Jiwa",
              "Total Laki Laki",
              "Total Perempuan",
              "Total KK",
            ],
          },
          yaxis: {
            min: 0,
            max: 120,
          },
          fill: {
            opacity: 1,
          },
          tooltip: {
            y: {
              formatter: function (val) {
                return val + " Kepala Keluarga";
              },
            },
          },
        },
      },
      chartOptions: {
        chart: {
          type: "bar",
        },
        title: {
          text: "",
        },
        xAxis: {
          categories: [
            "Total Jiwa",
            "Total Laki Laki",
            "Total Perempuan",
            "Total KK",
          ],
        },
        tooltip: {
          formatter: function () {
            return (
              "<b>" +
              this.x +
              "</b><br/>" +
              this.series.name +
              ": " +
              this.y +
              "<br/>" +
              "Total: " +
              this.point.stackTotal
            );
          },
        },
        yAxis: {
          min: 0,
          title: {
            text: "",
          },
        },
        legend: {
          symbolHeight: 12,
          symbolWidth: 12,
          symbolRadius: 2,
          verticalAlign: "top",
          enabled: true,
          reversed: true,
        },
        plotOptions: {
          series: {
            stacking: "normal",
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: [],
      },
    };
  },
  watch: {
    filter_tahun(newVal) {
      this.changeData(newVal);
    },
  },
  mounted() {
    this.getChartDataKeluarga();
  },
  methods: {
    getChartDataKeluarga() {
      this.$axios
        .get("/v1/sensus/jiwa-kk-pria-wanita")
        .then(async (response) => {
          let data = response.data;
          await this.chartOptions.series.push(
            {
              name: "Non-OAP",
              data: [
                data.jiwa.semua - data.jiwa.oap,
                data.pria.semua - data.pria.oap,
                data.wanita.semua - data.wanita.oap,
                data.kartu_keluarga.semua - data.kartu_keluarga.oap,
              ],
              color: "#5F8FDB",
            },
            {
              name: "OAP",
              data: [
                data.jiwa.oap,
                data.pria.oap,
                data.wanita.oap,
                data.kartu_keluarga.oap,
              ],
              color: "#15499A",
            }
          );
        });
    },
    changeData(filter_tahun) {
      this.$axios
        .post("/data-jiwa-kk-pria-wanita", null, {
          params: {
            filter_tahun: filter_tahun,
          },
        })
        .then(async (response) => {
          let data = response.data;
          this.chartOptions.series = [
            {
              name: "Non-OAP",
              data: [
                data.jiwa.semua - data.jiwa.oap,
                data.pria.semua - data.pria.oap,
                data.wanita.semua - data.wanita.oap,
                data.kartu_keluarga.semua - data.kartu_keluarga.oap,
              ],
              color: "#5F8FDB",
            },
            {
              name: "OAP",
              data: [
                data.jiwa.oap,
                data.pria.oap,
                data.wanita.oap,
                data.kartu_keluarga.oap,
              ],
              color: "#15499A",
            },
          ];
        });
    },
  },
};
</script>
